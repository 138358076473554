import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';
import { CrudService } from '@services/firestore-crud.service';

export enum ChronologyType {
  state = 'hronologija-drzave',
  battles = 'hronologija-bitaka',
  art = 'hronologija-umetnosti',
  sport = 'hronologija-sporta',
  persons = 'hronologija-velikana',
  movies = 'hronologija-filmografije',
}

export enum ChronologyUrl {
  state = 'razvoj-srpske-drzave',
  battles = 'slavne-bitke',
  art = 'istorija-umetnosti',
  sport = 'sportska-hronika',
  persons = 'biografije-velikana',
  movies = 'filmografija',
}

export const chronologyTypes = [
  { id: ChronologyType.state, url: ChronologyUrl.state, name: 'Историја државе', class: 'one' },
  { id: ChronologyType.battles, url: ChronologyUrl.battles, name: 'Славне битке', class: 'two' },
  { id: ChronologyType.persons, url: ChronologyUrl.persons, name: 'Биографије великана', class: 'three' },
  { id: ChronologyType.movies, url: ChronologyUrl.movies, name: 'Филмографија', class: 'six' },
  { id: ChronologyType.sport, url: ChronologyUrl.sport, name: 'Спортска хроника', class: 'four' },
  { id: ChronologyType.art, url: ChronologyUrl.art, name: 'Историја уметности', class: 'five' },
];

export interface Chronology {
  id?: string | null;
  age: number | null;
  title: string | null;
  innerHtml: string | null;
  imagePath?: string | null;
  typeId: ChronologyType | null;
  categoryId: string | null;
  epochId: string | null;
  wikipedia: string | null;
  imdb: string | null;
  created: number | null;
  updated: number | null;
}

export interface ChronologyGame extends Chronology {
  correctOrder: boolean;
  failDistance: number;
  gamePoints: number;
}

export const chronologyBoxes = [
  {
    path: ChronologyUrl.battles,
    style: 'box-2',
    icon: 'military_tech',
    title: 'Славне битке',
    image: `url('assets/images/bg-battle.jpg')`,
  },
  {
    path: ChronologyUrl.state,
    style: 'box-1',
    icon: 'timeline',
    title: 'Историја државе',
    image: `url('assets/images/bg-state.jpg')`,
  },
  {
    path: ChronologyUrl.persons,
    style: 'box-3',
    icon: 'emoji_people',
    title: 'Биографије великана',
    image: `url('assets/images/bg-bio.png')`,
  },
  {
    path: ChronologyUrl.movies,
    style: 'box-6',
    icon: 'movie',
    title: 'Филмографија',
    image: `url('assets/images/bg-movie.jpg')`,
  },
  {
    path: ChronologyUrl.sport,
    style: 'box-4',
    icon: 'sports_soccer',
    title: 'Спортска хроника',
    image: `url('assets/images/bg-sport.jpg')`,
  },
  {
    path: ChronologyUrl.art,
    style: 'box-5',
    icon: 'palette',
    title: 'Историја уметности',
    image: `url('assets/images/bg-art.jpg')`,
  },
];

@Injectable({ providedIn: 'root' })
export class ChronologyService extends CrudService<Chronology> {
  override collectionName = 'chronologies';

  constructor(firestore: Firestore, storage: Storage) {
    super(firestore, storage);
  }
}